ion-textarea {
    line-height: 1.5;
    font-size: 18px;
    font-weight: 400;
    color: var(--ion-color-dark);

    &.textarea {
        border: none;
        --border-radius: 8px;

        --placeholder-color: var(--ion-color-medium-tint);
        --background: var(--ion-color-light-shade);
    }
}

ion-alert {
  .alert-button {
    &.alert-danger-button {
      color: var(--ion-color-danger-tint);
    }
  }

  &.--multiline-options {
    .alert-radio-label.sc-ion-alert-ios {
      white-space: normal;
    }
  }
}

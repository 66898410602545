ion-toast {
  top: var(--space);
  --border-radius: 4px;
  --backdrop-opacity: 0.1;

  &::part(header) {
    font-weight: 600;
    font-size: 1rem;
  }

  &::part(message) {
    font-size: 1rem;
  }
}

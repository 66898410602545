/** Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ **/

/** Ionic CSS Variables **/
:root {
    --ion-color-primary: rgba(24, 78, 100, 1);
    --ion-color-primary-contrast: rgba(255, 255, 255, 1);
    --ion-color-primary-shade: rgba(21, 69, 88, 1);
    --ion-color-primary-tint: rgba(47, 96, 116, 1);

    --ion-color-secondary: rgba(143, 211, 220, 1);
    --ion-color-secondary-contrast: rgba(13, 13, 13, 1);
    --ion-color-secondary-shade: rgba(126, 186, 194, 1);
    --ion-color-secondary-tint: rgba(154, 215, 224, 1);

    --ion-color-tertiary: rgba(230, 216, 190, 1);
    --ion-color-tertiary-contrast: rgba(22, 22, 22, 1);
    --ion-color-tertiary-shade: rgba(202, 190, 167, 1);
    --ion-color-tertiary-tint: rgba(186, 176, 158, 1);

    --ion-color-success: rgba(68, 136, 101, 1);
    --ion-color-success-contrast: rgba(255, 255, 255, 1);
    --ion-color-success-shade: rgba(60, 120, 89, 1);
    --ion-color-success-tint: rgba(87, 148, 116, 1);

    --ion-color-warning: rgba(242, 185, 83, 1);
    --ion-color-warning-contrast: rgba(21, 21, 21, 1);
    --ion-color-warning-shade: rgba(213, 163, 73, 1);
    --ion-color-warning-tint: rgba(243, 192, 100, 1);

    --ion-color-danger: rgba(242, 68, 6, 1);
    --ion-color-danger-contrast: rgba(255, 255, 255, 1);
    --ion-color-danger-shade: rgba(213, 60, 5, 1);
    --ion-color-danger-tint: rgba(243, 87, 31, 1);

    --ion-color-dark: rgba(34, 36, 40, 1);
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: rgba(255, 255, 255, 1);
    --ion-color-dark-shade: rgba(30, 32, 35, 1);
    --ion-color-dark-tint: rgba(56, 58, 62, 1);

    --ion-color-medium: rgba(146, 148, 156, 1);
    --ion-color-medium-contrast: rgba(255, 255, 255, 1);
    --ion-color-medium-shade: rgba(128, 130, 137, 1);
    --ion-color-medium-tint: rgba(157, 159, 166, 1);

    --ion-color-light: rgba(255, 255, 255, 1);
    --ion-color-light-rgb: 255, 255, 255;
    --ion-color-light-contrast: rgba(70, 70, 70, 1);
    --ion-color-light-shade: rgba(224, 224, 224, 1);
    --ion-color-light-tint: rgba(250, 250, 250, 1);

    --ion-background-color: rgba(250, 250, 250, 1);
    --ion-background-color-rgb: rgba(250, 250, 250);

    --ion-text-color: rgba(34, 36, 40, 1);

    --ion-font-family: "SFProText", -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif, Roboto;

    --white: rgb(255, 255, 255);
    --black: rgb(0, 0, 0);
    --color-pdf: rgba(237, 85, 85, 1);
    --color-image: rgba(123, 97, 255, 1);
    --color-csv: #1f6f43;
    --backdrop-filter: rgba(255, 255, 255, 0.8);
    --border-color: rgba(00, 00, 00, 0.15);
    --space: 16px;
}

/*
   * Dark Colors
   * -------------------------------------------
   */

body.dark {
    --ion-color-primary: rgba(85, 151, 178, 1);
    --ion-color-primary-contrast: rgba(255, 255, 255, 1);
    --ion-color-primary-shade: rgb(61, 112, 135);
    --ion-color-primary-tint: rgb(108, 161, 184);

    --ion-color-secondary: rgb(48, 67, 70);
    --ion-color-secondary-contrast: rgb(235, 235, 235);
    --ion-color-secondary-shade: rgb(95, 116, 119);
    --ion-color-secondary-tint: rgb(34, 43, 44);

    --ion-color-tertiary: rgba(230, 216, 190, 1);
    --ion-color-tertiary-contrast: rgba(22, 22, 22, 1);
    --ion-color-tertiary-shade: rgba(202, 190, 167, 1);
    --ion-color-tertiary-tint: rgba(233, 220, 197, 1);

    --ion-color-success: rgba(96, 168, 131, 1);
    --ion-color-success-contrast: rgba(255, 255, 255, 1);
    --ion-color-success-shade: rgba(84, 148, 115, 1);
    --ion-color-success-tint: rgba(112, 177, 143, 1);

    --ion-color-warning: rgba(242, 185, 83, 1);
    --ion-color-warning-contrast: rgba(21, 21, 21, 1);
    --ion-color-warning-shade: rgba(213, 163, 73, 1);
    --ion-color-warning-tint: rgba(243, 192, 100, 1);

    --ion-color-danger: rgba(242, 68, 6, 1);
    --ion-color-danger-contrast: rgba(255, 255, 255, 1);
    --ion-color-danger-shade: rgba(213, 60, 5, 1);
    --ion-color-danger-tint: rgba(243, 87, 31, 1);

    --ion-color-dark: rgba(255, 255, 255, 1);
    --ion-color-dark-rgb: 255, 255, 255;
    --ion-color-dark-contrast: rgba(70, 70, 70, 1);
    --ion-color-dark-shade: rgba(224, 224, 224, 1);
    --ion-color-dark-tint: rgba(250, 250, 250, 1);

    --ion-color-medium: rgba(157, 158, 164, 1);
    --ion-color-medium-contrast: rgba(255, 255, 255, 1);
    --ion-color-medium-shade: rgba(138, 139, 144, 1);
    --ion-color-medium-tint: rgba(167, 168, 173, 1);

    --ion-color-light: rgba(34, 36, 40, 1);
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: rgba(255, 255, 255, 1);
    --ion-color-light-shade: rgba(30, 32, 35, 1);

    --white: rgba(0, 0, 0, 1);
    --black: rgba(255, 255, 255, 1);
    --color-pdf: rgba(237, 85, 85, 1);
    --color-image: rgba(123, 97, 255, 1);
    --color-csv: rgba(254, 220, 151, 1);
    --backdrop-filter: rgba(0, 0, 0, 0.8);
}

/*
   * iOS Dark Theme
   * -------------------------------------------
   */

.ios body.dark {
    --ion-background-color: rgba(56, 58, 62, 1);
    --ion-background-color-rgb: rgb(56, 58, 62);

    --ion-text-color: rgba(255, 255, 255, 1);
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
}

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body.dark {
    --ion-background-color: rgba(56, 58, 62, 1);
    --ion-background-color-rgb: rgb(56, 58, 62);

    --ion-text-color: rgba(255, 255, 255, 1);
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
}

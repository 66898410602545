ion-datetime {
  &.date-time {
    font-size: 18px;
    font-weight: 400;
    color: var(--ion-color-dark);

    padding: var(--space);
    height: 52px;
    border: none;
    border-radius: 8px;

    background-color: var(--ion-color-light-shade);

    --background: var(--ion-color-light-shade);

    /* stylelint-disable-next-line selector-pseudo-element-no-unknown, selector-type-no-unknown */
    &::part(placeholder) {
      color: var(--ion-color-medium-tint);
    }
  }
}

ion-radio-group {
  display: block;

  ion-item {
    margin: 0;

    ion-label {
      width: 1px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.408px;
      color: var(--ion-color-dark);
      margin: 0;
    }

    ion-radio {
      $size: 22px;
      width: $size;
      height: $size;
      margin: 0;
      --color-checked: var(--ion-color-success);

      &::part(mark) {
        margin: 0 0 3px 0;
      }

      &[slot="start"] {
        margin: 0 var(--space) 0 0;
      }
    }
  }
}

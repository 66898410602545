@mixin truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin truncate-text-multi-line($font-size, $line-height, $lines-to-show) {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines-to-show;
  display: -webkit-box;
  font-size: $font-size * 1px;
  max-height: $font-size * $line-height * $lines-to-show * 1px;
  line-height: $line-height;
  overflow: hidden;
  text-overflow: ellipsis;
}

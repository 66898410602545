ion-title {
  &.small {
    position: relative;

    padding: 0;
    margin: calc(var(--space) * 2) 0 calc(var(--space) / 2);

    font-size: 14px;
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
    color: var(--ion-color-medium);

    .content {
      display: flex;
      align-items: center;

      ion-button {
        padding: 0;
        margin: 0 0 0 auto;
        height: auto;
        width: auto;

        font-size: 14px;
        font-weight: 500;
        text-transform: none;

        --color: var(--ion-color-medium);
        --background: transparent;
        --background-activated: transparent;
      }
    }
  }

  &.big {
    position: relative;

    margin: calc(var(--space) * 2) 0;
    padding: 0;
    width: auto;
    height: auto;

    font-size: 32px;
    font-weight: 500;
    text-align: left;
    color: var(--ion-color-primary);
  }
}

.ion-datetime-button-overlay {
    --width: 100%;

    &#monthPickerModal {
        align-items: flex-end;
    }
}

@media screen and (min-width: 600px) {
    .ion-datetime-button-overlay {
        --width: 350px;
    }
}

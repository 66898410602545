ion-content {
  &.main-content {
    --padding-bottom: calc(15vh + var(--space));
    --padding-bottom: calc(15vh + var(--space) + env(safe-area-inset-bottom));

    @media (min-height: 500px) {
      --padding-bottom: calc(20vh + var(--space));
      --padding-bottom: calc(20vh + var(--space) + env(safe-area-inset-bottom));
    }

    @media (min-height: 600px) {
      --padding-bottom: calc(15vh + var(--space));
      --padding-bottom: calc(15vh + var(--space) + env(safe-area-inset-bottom));
    }
  }
}

ion-tabs {
  &.footer-tabs {
    ion-tab-bar {
      --border: 1px solid var(--border-color);
      --background: var(--backdrop-filter, #fff);

      .split-pane-visible & {
        display: none;
      }
    }

    ion-progress-bar {
      position: absolute;
      z-index: 10;
      bottom: 48px;
      bottom: calc(48px + env(safe-area-inset-bottom));

      .split-pane-visible & {
        bottom: 0;
      }
    }

    ion-tab-button {
      position: relative;
      --max-width: 100px;

      ion-icon {
        font-size: 28px;
        position: absolute;

        transition: visibility 0.25s ease, opacity 0.25s ease;

        &.outline {
          visibility: visible;
          opacity: 1;
        }

        &.filled {
          visibility: hidden;
          opacity: 0;
        }
      }
      &.tab-selected {
        ion-icon {
          color: var(--ion-color-primary-shade);

          &.outline {
            visibility: hidden;
            opacity: 0;
          }

          &.filled {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}

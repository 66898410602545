/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
@import "./theme/animate.scss";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Own ionic styles */
@import "./theme/variables.scss";
@import "./theme/ion-header";
@import "./theme/ion-footer";
@import "./theme/ion-content";
@import "./theme/ion-tabs";
@import "./theme/ion-searchbar";
@import "./theme/ion-title";
@import "./theme/ion-input";
@import "./theme/ion-select";
@import "./theme/ion-label";
@import "./theme/ion-textarea";
@import "./theme/ion-alert";
@import "./theme/ion-datetime.scss";
@import "./theme/ion-modal.scss";
@import "./theme/ion-toast.scss";
@import "./theme/ion-radio-group.scss";
@import "./theme/ion-checkbox.scss";
@import "./theme/ion-item.scss";

/* Own fonts */
@font-face {
    font-family: "SFProText";
    src:
        url("./assets/fonts/sfprotext-light.woff2") format("woff2"),
        url("./assets/fonts/sfprotext-light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SFProText";
    src:
        url("./assets/fonts/sfprotext-regular.woff2") format("woff2"),
        url("./assets/fonts/sfprotext-regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SFProText";
    src:
        url("./assets/fonts/sfprotext-medium.woff2") format("woff2"),
        url("./assets/fonts/sfprotext-medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SFProText";
    src:
        url("./assets/fonts/sfprotext-semibold.woff2") format("woff2"),
        url("./assets/fonts/sfprotext-semibold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* Layout */

/* Shadows */
body {
    --shadow-200: 0 4px 6px -4px rgba(24, 39, 75, 0.12), 0 8px 8px -4px rgba(24, 39, 75, 0.08);
    --shadow-400-invert: 0 -6px 12px -6px rgba(24, 39, 75, 0.12), 0 -8px 24px -4px rgba(24, 39, 75, 0.08);
}

.--offline-hide,
.--role-hide {
    display: none !important; /* stylelint-disable declaration-no-important */
}

mark {
    background-color: var(--ion-color-warning-tint);
    color: var(--ion-color-warning-contrast);
}

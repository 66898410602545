@import "./vendors.scss";

ion-select {
    font-size: 18px;
    font-weight: 400;
    color: var(--ion-color-dark);
    width: 100%;
    min-height: 52px;
    border: none;

    --padding-top: var(--space);
    --padding-bottom: var(--space);
    --padding-start: var(--space);
    --padding-end: var(--space);
    --border-radius: 8px;

    --background: var(--ion-color-light-shade);

    &::part(text) {
        @include truncate-text();
    }

    &::part(label) {
        display: none;
    }
}

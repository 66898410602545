ion-header {
    --background: var(--ion-background-color, #fff);
    z-index: 101;

    ion-title {
        --color: var(--ion-color-text);
    }

    :not(ion-content) & {
        ion-toolbar {
            --background: var(--ion-background-color, #fff);
        }

        ion-icon {
            color: var(--ion-color-primary-tint);
            font-size: 30px;
            &[name="ellipsis-vertical-outline"] {
                font-size: 27px;
            }
        }
    }

    ion-content & {
        &.sticky {
            /** TODO: fix sticky position in iOS safari **/
            position: -webkit-sticky;
            position: -moz-sticky;
            position: -ms-sticky;
            position: -o-sticky;
            position: sticky;
            top: 0;
            --background: var(--ion-background-color, #fff);
            z-index: 101;
        }
    }

    &.--no-padding-top {
        ion-toolbar {
            padding-top: 0;
        }
    }
}

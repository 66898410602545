ion-input {
    $height: 52px;
    font-size: 18px;
    font-weight: 400;
    color: var(--ion-color-dark);

    &.input {
        flex: auto;

        height: $height;
        border: none;
        --border-radius: 8px;

        --placeholder-color: var(--ion-color-medium-tint);
        --background: var(--ion-color-light-shade);
    }

    &[error-message]:not([error-message=""]) {
        &.ion-invalid.ion-touched {
            &:after {
                $padding: 4px;
                $font-size: 12px;
                $line-height: 1.2;
                $height-error: $font-size * $line-height;
                content: attr(error-message);
                position: absolute;
                top: 0;
                margin-left: var(--space);
                background-color: var(--ion-color-light-shade);
                color: var(--ion-color-danger);
                font-size: $font-size;
                line-height: $line-height;
                padding: $padding $padding * 2;
                max-width: 80%;
                min-width: 30px;
                margin-top: $height - $font-size;
                border: 1px solid var(--ion-color-light);
                border-radius: ($height-error + $padding);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
